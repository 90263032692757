import { useRef } from 'react'
import { Modal, ModalProps, Text, Stack, Button, ModalFooter, Box, Alert } from '@thirstycamel/ui'
import type OrderEntity from '@ts/core/src/modules/order/order.entity'

export interface ModalConfirmCompleteOrderProps extends Omit<ModalProps, 'order'> {
  onDismiss?: () => void
  onConfirm?: () => void
  order?: OrderEntity
  orderStatus?: string
}

const ModalConfirmCompleteOrder: React.FC<ModalConfirmCompleteOrderProps> = ({
  isVisible,
  onDismiss,
  onConfirm,
  order,
  orderStatus,
}) => {
  const initialFocusRef = useRef(null)

  return (
    <Modal
      onClose={onDismiss}
      isVisible={isVisible}
      initialFocusRef={initialFocusRef}
      heading="Confirm Order Complete"
    >
      <Stack spacing={5}>
        <Stack spacing={3}>
          <Text fontWeight="bold">Manually mark order as completed?</Text>
          {/* <Text>
            This should be used if the order has been collected and delivered.
          </Text> */}
          <Alert variant="left-accent" status="passive">
            <Box>
              <Text fontSize="sm" color="blackAlpha.700">
                Order status:
              </Text>
              <Text textTransform="uppercase" fontWeight="medium">
                {orderStatus || 'Unknown'}
              </Text>
            </Box>
          </Alert>
          <Text>
            <i>
              <b>Please note:</b> orders are automatically marked as 'Completed' when the delivery
              partner drops off the order to the customer.
            </i>
          </Text>
        </Stack>

        <ModalFooter>
          <Button
            variant="outline"
            variantColor="pink"
            ref={initialFocusRef}
            isRound
            mr={3}
            onClick={onDismiss}
          >
            Cancel
          </Button>

          <Button
            variantColor="pink"
            isRound
            onClick={() => {
              onConfirm?.()
              onDismiss?.()
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Stack>
    </Modal>
  )
}

export default ModalConfirmCompleteOrder
