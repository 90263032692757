import { modal, ModalModel } from './modal'
import auth, { AuthModel } from './auth'

export interface StoreModel {
  auth: AuthModel
  modal: ModalModel
}

const model: StoreModel = {
  auth,
  modal,
}

export default model
