import { useRef, useState } from 'react'
import {
  Modal,
  ModalProps,
  Text,
  Stack,
  Button,
  ModalFooter,
  TextInput,
  SelectInput,
  FormErrorMessage,
  FormHelperText,
  useBackendQuery,
  Spinner,
} from '@thirstycamel/ui'
import { useAuthHeaders } from '../../hooks/useAuthHeaders'
import omit from 'lodash.omit'
import { OwnDriver } from '@ts/core/src/modules/order/order.object'
import { useStore } from '../../store/hooks'

type ErrorFields = 'phone'

export interface ModalBookOwnDriverProps extends ModalProps {
  orderId: string
  onDismiss?: () => void
  onConfirm?: ({ name, phone}: { name: string, phone: string}) => void
}

const ModalBookOwnDriver: React.FC<ModalBookOwnDriverProps> = ({
  isVisible,
  orderId, 
  onDismiss,
  onConfirm,
}) => {
  const authHeaders = useAuthHeaders()
  const { showing } = useStore(store => store.modal)

  let { data: drivers, isLoading, } = useBackendQuery<OwnDriver[]>([
    `/orders/${orderId}/own-drivers`,
    authHeaders,
  ], {
      enabled: showing?.includes('BOOK_OWN_DRIVER')
  })


  const initialFocusRef = useRef(null)

  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [errors, setErrors] = useState<{ [key in ErrorFields]?: string }>({})

  const isReadyToSubmit = name && phone
  const hasId = id != null && id != ''

  const handleChangeName = value => {
    setId('')
    setName(value)
  }

  const handleChangePhone = value => {
    setId('')
    setPhone(value)
    if (errors.phone) setErrors(state => omit(state, 'phone'))
  }

  const handleSelectDriver = (driver) => {
    console.log('handleSelectDriver', driver)
    setId(driver?.id)
    setName(driver?.name)
    setPhone(driver?.phone)
    if (errors.phone) setErrors(state => omit(state, 'phone'))
  }

  const handleConfirm = () => {
    const isPhoneValid = phone.match(/^04\d{8}$/)

    const _errors: typeof errors = {}

    if (!isPhoneValid) _errors.phone = 'Mobile number must match the format 04XXXXXXXX.'

    setErrors(_errors)

    if (Object.keys(_errors).length > 0) return

    onDismiss?.()
    onConfirm?.({
      ...(hasId ? {id} : {}),
      name,
      phone
    })
  }

  return (
    <Modal
      onClose={onDismiss}
      isVisible={isVisible}
      initialFocusRef={initialFocusRef}
      heading="Book Own Driver"
    >
      <Stack spacing={5}>
        <Stack spacing={2} pr={4}>
          <Text>
            Enter the <b>name</b> and <b>mobile number</b> of the driver who will deliver the order to the customer.
            <br/>
            You can select a driver from the list of saved drivers.
          </Text>
        </Stack>

        {isLoading ? (
          <Stack bg="gray.50" border="1px" borderColor="gray.200" p={5} spacing={4}>
          <Text>Loading saved own drivers...</Text>

          <Spinner size="sm" color="pink.500" mx="auto" my={5} />

          </Stack>
        ) : drivers?.length > 0 ? (
          <Stack bg="gray.50" border="1px" borderColor="gray.200" p={5} spacing={4}>
          <Text>Select an existing driver</Text>

          <SelectInput
            name="region"
            options={drivers?.map(driver => ({value: driver?.id, label: `${driver?.name}, ${driver?.phone}`}))}
            placeholder={'Select a driver...'}
            onChangeValue={value => {
            
              handleSelectDriver(drivers?.find(driver => driver?.id === value))
            }}
            border={0}
            dir="ltr"
          />
        </Stack>
        ) : (
          <Stack bg="gray.50" border="1px" borderColor="gray.200" p={5} spacing={4}>
            <Text>No existing drivers found for this store.</Text>
          </Stack>
        )
        }
       
        <Stack bg="gray.50" border="1px" borderColor="gray.200" p={5} spacing={4}>
          <Text fontSize="sm">Driver Name:</Text>
          <TextInput value={name} onChangeValue={handleChangeName} placeholder="eg. John Smith" />

          <Text fontSize="sm">Driver Phone:</Text>
          <TextInput value={phone} onChangeValue={handleChangePhone} placeholder="eg. 0400123456" maxLength={10} isInvalid={!!errors.phone}/>
          {errors.phone && <FormErrorMessage error={errors.phone} />}
          <FormHelperText>Please enter an Australian mobile number.</FormHelperText>
        </Stack>

        <ModalFooter>
          <Button
            variant="outline"
            variantColor="pink"
            ref={initialFocusRef}
            isRound
            mr={3}
            onClick={onDismiss}
          >
            Cancel
          </Button>

          <Button variantColor="pink" isRound onClick={handleConfirm} isDisabled={!isReadyToSubmit}>
            Confirm
          </Button>
        </ModalFooter>
      </Stack>
    </Modal>
  )
}

export default ModalBookOwnDriver
