import { Global, css } from '@emotion/core'

export const GlobalStyle = () => (
  <Global
    styles={css`
      @font-face {
        font-family: 'Lexend-Black';
        src: url('/static/fonts/Lexend-Black.woff2') format('woff2'),
          url('/static/fonts/Lexend-Black.woff') format('woff');
        font-weight: 900;
        font-style: normal;
      }
      @font-face {
        font-family: 'HelveticaNeue-CondensedBold';
        src: url('/static/fonts/HelveticaNeue-CondensedBold.woff2') format('woff2'),
          url('/static/fonts/HelveticaNeue-CondensedBold.woff') format('woff');
        font-weight: 600;
        font-style: normal;
      }
      @font-face {
        font-family: 'HelveticaNeue';
        src: url('/static/fonts/HelveticaNeue.woff2') format('woff2'),
          url('/static/fonts/HelveticaNeue.woff') format('woff');
        font-weight: 400;
        font-style: normal;
      }

      html,
      body,
      #__next {
        min-height: 100vh;
      }
    `}
  />
)

export default GlobalStyle
