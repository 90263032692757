import {
  Stack,
  Card,
  Heading,
  IconButton,
  StackProps,
  Link,
  Image,
  Flex,
  Box,
} from '@thirstycamel/ui'
import { isFuture, isPast } from 'date-fns'
import BadgeEntity from '@ts/core/src/modules/badge/badge.entity'
import imageProxy from '../../utils/imageProxy'

export const ProductBadgeSelect = ({
  badge,
  onSelect,
  onRemove,
  isLoading,
  isSelected,
  ...restProps
}: StackProps & {
  badge: BadgeEntity
  isLoading?: boolean
  isSelected?: boolean
  onSelect?: () => void
  onRemove?: () => void
}) => {
  const isOver = badge?.toDate && isPast(new Date(badge.toDate))
  const isSoon = badge?.fromDate && isFuture(new Date(badge.fromDate))

  const bg = isSoon ? 'yellow.500' : isOver ? 'red.500' : 'green.500'
  const title = isSoon ? 'Pending' : isOver ? 'Finished' : 'Active'

  return (
    <Stack
      as={Card}
      isInline
      spacing={4}
      bg="gray.50"
      py={3}
      px={4}
      flex="1 1 0"
      justify="space-between"
      flexShrink={0}
      borderColor={isSelected ? 'pink.400' : 'gray.200'}
      {...restProps}
    >
      <Stack isInline>
        <Flex align="center" pr={2}>
          <Box size="12px" borderRadius="50%" bg={bg} title={title} />
        </Flex>

        <Flex
          justify="center"
          align="center"
          flexShrink={0}
          // transform="rotate(-10deg)"
          // borderRadius="50%"
          overflow="hidden"
        >
          {badge?.image ? (
            <Image
              src={imageProxy(badge?.image?.filename, { height: 40 })}
              height={['40px']}
              objectFit="contain"
            />
          ) : (
            <Box w="40px" h="40px" />
          )}
        </Flex>

        <Stack spacing={1} flexShrink={1}>
          <Heading fontSize="xs" textTransform="uppercase" color="pink.500">
            {badge.name}
          </Heading>

          <Heading fontSize="2xs">{badge.description}</Heading>
        </Stack>
      </Stack>

      <Stack isInline>
        {isSelected ? (
          <IconButton
            as="div"
            onClick={() => onRemove()}
            icon="delete"
            variant="outline"
            isLoading={isLoading}
            variantColor="pink"
            fontSize="lg"
            hasDepth
            flexShrink={0}
          />
        ) : (
          <IconButton
            as="div"
            onClick={() => onSelect()}
            icon="check"
            variant="outline"
            isLoading={isLoading}
            variantColor="pink"
            fontSize="lg"
            hasDepth
            flexShrink={0}
          />
        )}

        <Link href={`/media/badges/[id]`} hrefAs={`/media/badges/${badge.id}`}>
          <IconButton
            as="div"
            icon="view"
            variant="outline"
            isLoading={isLoading}
            variantColor="pink"
            fontSize="lg"
            hasDepth
            flexShrink={0}
          />
        </Link>
      </Stack>
    </Stack>
  )
}

export default ProductBadgeSelect
