import { backend, mutation, RequestOptions } from '../utils/backend'
import { Paginated } from '../utils/pagination'
import { useDebounce } from 'use-debounce'
import { useMutation, useQuery } from 'react-query'
import { useState, FC } from 'react'
import {
  Box,
  Card,
  useForm,
  TextInput,
  Stack,
  Text,
  Button,
  FormLabel,
  FormControl,
  Container,
  BackendError,
} from '@thirstycamel/ui'
import type { LiquorfileProduct } from '@ts/core/src/modules/liquorfile/liquorfile.objects'
import type { CreateCoreProduct } from '@ts/core/src/modules/coreproduct/coreproduct.objects'
import type { CoreProductEntity } from '@ts/core/src/modules/coreproduct/coreproduct.entity'
import { useActions } from '../store/hooks'
import { AxiosError } from 'axios'

const CreateProduct: FC = ({ ...rest }) => {
  const form = useForm<CreateCoreProduct>()
  const onComplete = useActions(a => a.modal.hideModal)

  const [search, setSearch] = useState<void | string>()
  const [value] = useDebounce(search, 200)

  const { data: results, isFetching, error: queryError } = useQuery<
    Paginated<LiquorfileProduct>,
    BackendError,
    [string, RequestOptions]
  >(!!value && [`/products/liquorfile`, { params: { search: value, page: 0, limit: 10 } }], backend)

  const [create, { error }] = useMutation<CoreProductEntity, BackendError, CreateCoreProduct>(
    mutation('products'),
    {
      onSuccess: () => onComplete('CREATE_PRODUCT'),
    },
  )

  const isLoading = search !== value || isFetching

  const selectLiquorfile = (product: LiquorfileProduct) => {
    setSearch()

    form.setValue('name', product.LongDescription)
    form.setValue('liquorfileNo', product.LiquorfileNo.toString())

    form.setValue('unitSize', product.UnitSize)

    form.setValue('factor', product.Factor)

    form.setValue('factorDescription', product.FactorDescription)
    form.setValue('packQty', Number(product.PackQty))

    form.setValue('volume', product.Volume)

    form.setValue('categoryCode', product.CategoryCode.toString())
    form.setValue('category', product.Category)

    form.setValue('costPrice', product.CostPrice)
    form.setValue('barcodes', product.Barcodes)
  }

  const onSubmit = async (values: CreateCoreProduct) => {
    await create({
      ...values,
      packQty: Number(values.packQty),
      factor: Number(values.factor),
      volume: Number(values.volume),
      barcodes: [],
    })
  }

  return (
    <Container size="sm" p={5}>
      <Card as="form" p={5} {...rest} onSubmit={form.handleSubmit(onSubmit)}>
        <Stack spacing={5}>
          <Stack>
            <Stack isInline>
              <FormControl>
                <FormLabel htmlFor="brandName">Brand</FormLabel>
                <TextInput name="brandName" ref={form.register} />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="name">Name</FormLabel>
                <TextInput
                  name="name"
                  placeholder="Product Name"
                  ref={form.register}
                  onChange={e => setSearch(e.target.value)}
                />

                {isLoading && search && <Text>loading</Text>}

                {!isLoading && search && (
                  <Stack isInline spacing={1} overflowX="auto" p={1}>
                    {results?.items.map(i => (
                      <Button onClick={() => selectLiquorfile(i)}>{i.ShorterDescription}</Button>
                    ))}
                  </Stack>
                )}
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="liquorfileNo">Liquorfile No.</FormLabel>
                <TextInput name="liquorfileNo" ref={form.register} />
              </FormControl>
            </Stack>

            <Stack isInline spacing={2} shouldWrapChildren>
              <FormControl>
                <FormLabel htmlFor="unitSize">Unit Size (ml)</FormLabel>
                <TextInput name="unitSize" ref={form.register} />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="volume">Volume (ml)</FormLabel>
                <TextInput name="volume" type="number" ref={form.register} />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="packQty">Pack Quantity</FormLabel>
                <TextInput name="packQty" type="number" ref={form.register} />
              </FormControl>
            </Stack>
            <Stack isInline spacing={2} shouldWrapChildren>
              <FormControl>
                <FormLabel htmlFor="factor">Factor</FormLabel>
                <TextInput name="factor" type="number" ref={form.register} />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="factorDescription">Factor Description</FormLabel>
                <TextInput name="factorDescription" ref={form.register} />
              </FormControl>
            </Stack>
            <Stack isInline spacing={2} shouldWrapChildren>
              <FormControl>
                <FormLabel htmlFor="category">Category</FormLabel>
                <TextInput name="category" ref={form.register} />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="categoryCode">Category Code</FormLabel>
                <TextInput name="categoryCode" ref={form.register} />
              </FormControl>
            </Stack>

            <FormControl>
              <FormLabel htmlFor="description">Description</FormLabel>
              <TextInput name="description" ref={form.register} />
            </FormControl>
          </Stack>
          <pre>{JSON.stringify((error as AxiosError)?.response, null, 2)}</pre>
          <Stack isInline spacing={2}>
            <Button type="submit" variantColor="green">
              Submit
            </Button>
          </Stack>
        </Stack>
      </Card>
    </Container>
  )
}

export default CreateProduct
