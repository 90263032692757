import { useEffect } from 'react'
import Router from 'next/router'

export const useRouteChange = (onRouteChange: any) => {
  useEffect(() => {
    Router.events.on('routeChangeStart', onRouteChange)

    return () => {
      Router.events.off('routeChangeStart', onRouteChange)
    }
  }, [onRouteChange])
}
