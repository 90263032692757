import { useRef, useState } from 'react'

import {
  Modal,
  ModalProps,
  Text,
  Stack,
  Button,
  Card,
  Heading,
  Icon,
  IconButton,
  Divider,
  useToast,
  Box,
  useBackendQuery,
} from '@thirstycamel/ui'

import { useActions, useStore } from '../../store/hooks'
import { getNiceErrorMessage } from '../../utils/getNiceErrorMessage'
import BadgeEntity from '@ts/core/src/modules/badge/badge.entity'
import { useAuthHeaders } from '../../hooks/useAuthHeaders'
import ProductBadgeSelect from '../ProductBadgeSelect'
import { CoreProductEntity } from '@ts/core/src/modules/coreproduct/coreproduct.entity'

export interface ModalSelectBadgeProps extends ModalProps {
  onDismiss?: () => void
  onConfirm?: (badge: BadgeEntity) => void
  onRemove?: (badge: BadgeEntity) => void
  product: CoreProductEntity
}

const ModalSelectBadge: React.FC<ModalSelectBadgeProps> = ({
  isVisible,
  onDismiss,
  onConfirm,
  onRemove,
  product,
}) => {
  const toast = useToast()

  let { isFetching, error, data }: any = useBackendQuery<BadgeEntity[]>([
    `badges`,
    {
      params: { hasImage: true },
      ...useAuthHeaders(),
    },
  ])

  const initialFocusRef = useRef(null)

  const handleSelectBadge = async (badge: BadgeEntity) => {
    onConfirm(badge)
  }

  const handleRemoveBadge = async (badge: BadgeEntity) => {
    onRemove(badge)
  }

  return (
    <>
      <Modal
        onClose={onDismiss}
        isVisible={isVisible}
        initialFocusRef={initialFocusRef}
        heading="Badge"
        maxWidth={['lg', 'xl', '2xl']}
      >
        <Stack py={2} spacing={5} flexShrink={0}>
          {data?.items?.length ? (
            <Stack spacing={5} flexShrink={0}>
              <Text fontSize="sm">Select a badge to display with this product.</Text>
              <Divider />
              <Box flexShrink={0}>
                {data.items.map(badge => (
                  <ProductBadgeSelect
                    badge={badge}
                    isLoading={isFetching}
                    isSelected={product?.badge && product.badge?.id === badge?.id}
                    onSelect={() => {
                      handleSelectBadge(badge)
                    }}
                    onRemove={() => {
                      handleRemoveBadge(badge)
                    }}
                    mb={2}
                  />
                ))}
              </Box>
            </Stack>
          ) : (
            <Text>No badges found.</Text>
          )}
          <Button
            variantColor="pink"
            isLoading={isFetching}
            onClick={() => {
              onDismiss()
            }}
            flexShrink={0}
          >
            Close
          </Button>
        </Stack>
      </Modal>
    </>
  )
}

export default ModalSelectBadge
