import App, { Container } from 'next/app'
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import Router from 'next/router'
import { ReactQueryDevtools } from 'react-query-devtools'
import { ThemeProvider, CSSReset, theme, BackendProvider, NProgress } from '@thirstycamel/ui'
import { StoreProvider } from 'easy-peasy'
import { parseCookies } from 'nookies'
import * as Sentry from '@sentry/node'

import { backend, mutation, humpclub, humpclubMutation } from '../utils/backend'
import withRedux from '../utils/withRedux'
import ModalRoot from '../components/ModalRoot/ModalRoot'
import GlobalStyle from './_style'

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: 'https://d059252ebb21407f8346fbfb45edeee7@o456981.ingest.sentry.io/5565174',
})

class MyApp extends App<any> {
  public static getInitialProps = async ({ Component, ctx }) => {
    let pageProps = {}

    const { portal_token, impersonation_token } = parseCookies(ctx)

    if (impersonation_token) {
      await ctx.store.dispatch.auth.setImpersonationToken(impersonation_token)
    }

    try {
      if (portal_token) {
        await ctx.store.dispatch.auth.setToken({ accessToken: portal_token })
        await ctx.store.dispatch.auth.getUser()
      }
    } catch (e) {}

    /* Fetch the currently selected store, which is set in the user's cookies. */
    // if (selected_store) {
    //   await ctx.store.dispatch.store.fetchStore(selected_store)
    // }

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)
    }

    return { pageProps }
  }

  render() {
    const { Component, pageProps, store, err } = this.props

    return (
      <>
        <NProgress />
        <ReactQueryDevtools />

        <StoreProvider store={store}>
          <BackendProvider
            query={backend}
            mutation={mutation}
            humpclubQuery={humpclub}
            humpclubMutation={humpclubMutation}
          >
            <ThemeProvider theme={theme}>
              <CookiesProvider>
                <CSSReset />
                <GlobalStyle />
                <ModalRoot />

                <Component {...pageProps} err={err} />
              </CookiesProvider>
            </ThemeProvider>
          </BackendProvider>
        </StoreProvider>
      </>
    )
  }
}

export default withRedux(MyApp)
