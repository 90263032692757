import { memo, FC } from 'react'
import { MODALS } from '../../store/model/modal'
import { useActions, useStore } from '../../store/hooks'
import { useRouteChange } from '../../hooks/useRouteChange'

export type Modals = { [key: string]: FC<any> }

const ModalRoot: FC = memo(() => {
  const modals = useStore(state => state.modal)

  const { hideModal, hideAllModals } = useActions(state => state.modal)

  /* Hide all modals on route change. */
  useRouteChange(hideAllModals)

  if (modals.showing.length === 0) return null

  return (
    <>
      {modals.showing.map(modalName => {
        const modalType = modalName.split('-')[0]
        const Component = MODALS[modalType]

        return (
          <Component
            key={modalName}
            isVisible
            onDismiss={() => hideModal(modalName as keyof typeof MODALS)}
            modalName={modalName}
            {...modals.data?.[modalName]?.props}
          />
        )
      })}
    </>
  )
})

export default ModalRoot
