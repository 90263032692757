import { action, Action } from 'easy-peasy'
import CreateProduct from '../../modals/CreateProduct'
import ModalOrderFraudCheck from '../../components/ModalOrderFraudCheck/ModalOrderFraudCheck'
import ModalConfirmCompleteOrder from '../../components/ModalConfirmCompleteOrder'
import ModalSelectBadge from '../../components/ModalSelectBadge'
import ModalBookOwnDriver from '../../components/ModalBookOwnDriver/ModalBookOwnDriver'

export const MODALS = {
  CREATE_PRODUCT: CreateProduct,
  ORDER_FRAUD_CHECK: ModalOrderFraudCheck,
  CONFIRM_COMPLETE_ORDER: ModalConfirmCompleteOrder,
  SELECT_BADGE: ModalSelectBadge,
  BOOK_OWN_DRIVER: ModalBookOwnDriver,
}

type showModalPayload =
  | keyof typeof MODALS
  | {
      name: keyof typeof MODALS
      id?: string
      props?: any
    }

type hideModalPayload =
  | keyof typeof MODALS
  | {
      name: keyof typeof MODALS
      id?: string
    }

type updateModal = {
  name: keyof typeof MODALS
  id?: string
  props: any
  replace?: boolean
}

export interface ModalModel {
  showing: string[]
  data: {
    [modalId: string]: any
  }
  showModal: Action<ModalModel, showModalPayload>
  hideModal: Action<ModalModel, hideModalPayload>
  hideAllModals: Action<ModalModel>
  updateModal: Action<ModalModel, updateModal>
}

export const modal: ModalModel = {
  showing: [],
  data: {},
  showModal: action((state, payload) => {
    if (typeof payload === 'string') {
      state.showing.push(payload)
      return
    }

    const name = payload.id ? `${payload.name}-${payload.id}` : payload.name

    state.showing.push(name)
    state.data[name] = { ...state[name], props: payload.props }
  }),
  hideModal: action((state, payload) => {
    let name: string

    if (typeof payload === 'string') {
      name = payload
    } else {
      name = payload.id ? `${payload.name}-${payload.id}` : payload.name
    }

    state.showing = state.showing.filter(modalName => modalName !== name)
  }),
  hideAllModals: action(state => {
    state.showing = []
  }),
  updateModal: action((state, { id, name, props, replace }) => {
    const modalName = id ? `${name}-${id}` : name

    state.data[modalName].props = replace ? props : { ...state[modalName].props, ...props }
  }),
}
